import styled from 'styled-components';

import { Copy, H1, H2 } from '../../components/atoms/Typography';
import { BulletList } from '../../components/atoms/BulletList';

const PrivacyPolicy = () => {
  return (
    <>
      <H1>JobsBoard Privacy Policy</H1>

      <Copy styleLevel={1} marginBottom={16}>
        <strong>Effective Date:</strong> {new Date().toISOString().slice(0, 10)}
      </Copy>

      <Copy styleLevel={2} marginBottom={16}>
        Welcome to JobsBoard.io. Your privacy is important to us. This Privacy Policy explains how we collect, use, and
        protect your personal information when you use our website, [www.JobsBoard.io]. By accessing or using our
        website, you consent to the practices described in this Privacy Policy.
      </Copy>

      <Copy styleLevel={2} marginBottom={16}>
        Jobsboard.io's core mission is to provide a Smart Dashboard to help you manage your job search. match candidates
        with a job they love and to connect the world's most innovative companies with top tech talent. It is,
        therefore, an essential part of our service that information you submit through the service be made available to
        other users. Your information may also be shared with other Jobsboard.io services of which you are not a user,
        as well as potentially transmitted to third parties as set forth herein. Jobsboard.io is committed to protecting
        the privacy of its users and the information that you share in connection with your use of Jobsboard.io's
        services. This Privacy Notice describes how we collect, use, share, transfer, and disclose your personal
        information. This Privacy Notice applies to information we collect when you access and/or use our services.
        Jobsboard.io may provide additional notices through our services in addition to these practices or provide you
        additional choices about how Jobsboard.io may process your personal information. If you have any questions about
        this Privacy Notice, please contact us at support@jobsboard.io.
      </Copy>

      <Copy styleLevel={2} marginBottom={16}>
        By submitting information, including Personal Information, through Our Services, You agree to the terms of this
        Privacy Notice
      </Copy>

      <Copy styleLevel={2} marginBottom={16}>
        Changes to this Privacy Notice: We reserve the right to modify the provisions of this Privacy Notice at any
        time, and we will indicate the changes by revising the date at the top of this document. We encourage you to
        review this Privacy Notice whenever you access our services to ensure that you comprehend our information
        collection, use, and disclosure practices. In the event of significant changes to this Privacy Notice, we will
        provide you with prominent notice as deemed appropriate under the circumstances. This notice may be displayed
        conspicuously within the service interface or communicated to you via email. We may also notify you in advance
        of these changes whenever possible. Your continued use of our services after such modifications signifies your
        acceptance of the updated Privacy Notice.
      </Copy>

      <H2>Information We Collect</H2>

      <Copy styleLevel={2} marginBottom={16}>
        We may collect personal information from you when you interact with our website. This information may include,
        but is not limited to, your name, email address, postal address, phone number, and any other information you
        voluntarily provide to us.
      </Copy>

      <Copy styleLevel={2} marginBottom={16}>
        <BulletList>
          <li>
            <FirstLi>
              <strong>Device Attributes:</strong>
            </FirstLi>
            <ul>
              <li>Information such as the operating system, hardware, and software versions</li>
              <li>Battery level, signal strength, available storage space</li>
              <li>Browser type, app and file names and types, and plugins</li>
            </ul>
          </li>
          <li>
            <FirstLi>
              <strong>Device Operations:</strong>
            </FirstLi>
            <ul>
              <li>Details about operations performed on the device</li>
              <li>Whether a window is foregrounded or backgrounded</li>
              <li>Mouse movements (helpful for distinguishing humans from bots)</li>
            </ul>
          </li>
          <li>
            <FirstLi>
              <strong>Identifiers:</strong>
            </FirstLi>
            <ul>
              <li>Unique identifiers and device IDs</li>
              <li>Identifiers from games, apps, or accounts you use</li>
              <li>
                Family device IDs or other identifiers unique to services associated with the same device or account
              </li>
            </ul>
          </li>
          <li>
            <FirstLi>
              <strong>Device Signals:</strong>
            </FirstLi>
            <ul>
              <li>Bluetooth signals</li>
              <li>Information about nearby Wi-Fi access points, beacons, and cell towers</li>
            </ul>
          </li>
          <li>
            <FirstLi>
              <strong>Data from Device Settings:</strong>
            </FirstLi>
            <ul>
              <li>Information you allow us to receive through device settings you turn on</li>
              <li>Access to your GPS location, camera, or photos</li>
            </ul>
          </li>
          <li>
            <FirstLi>
              <strong>Network and Connections:</strong>
            </FirstLi>
            <ul>
              <li>Name of your mobile operator or ISP</li>
              <li>Language, time zone, mobile phone number</li>
              <li>IP address, connection speed</li>
              <li>Information about other devices connected to services nearby or on your network</li>
            </ul>
          </li>
          <li>
            <FirstLi>
              <strong>Cookie Data:</strong>
            </FirstLi>
            <ul>
              <li>Information from cookies stored on your device</li>
              <li>Cookie IDs and settings</li>
            </ul>
          </li>
        </BulletList>
      </Copy>

      <H2>How We Use Your Information</H2>

      <Copy styleLevel={2} marginBottom={16}>
        We use the information we collect for various purposes, including:
      </Copy>

      <BulletList>
        <li>
          <FirstLi>
            <strong>To Provide, Develop, and Enhance Services:</strong>
          </FirstLi>
          <ul>
            <li>Continuously improve the services offered, ensuring a seamless user experience.</li>
          </ul>
        </li>
        <li>
          <FirstLi>
            <strong>Provide and Deliver Requested Products and Services:</strong>
          </FirstLi>
          <ul>
            <li>Process transactions, deliver requested products and services promptly.</li>
            <li>Send confirmations and invoices related to your transactions.</li>
          </ul>
        </li>
        <li>
          <FirstLi>
            <strong>Feedback Response and Service Security:</strong>
          </FirstLi>
          <ul>
            <li>Address user feedback promptly to enhance service quality.</li>
            <li>Monitor and ensure the security of the provided services.</li>
          </ul>
        </li>
        <li>
          <FirstLi>
            <strong>Enforcement of Terms and Rights:</strong>
          </FirstLi>
          <ul>
            <li>Enforce terms and conditions outlined in agreements between you and the service provider.</li>
            <li>Safeguard the rights of both parties involved.</li>
          </ul>
        </li>
        <li>
          <FirstLi>
            <strong>Other Necessary Actions:</strong>
          </FirstLi>
          <ul>
            <li>Undertake any necessary action deemed essential by the service provider.</li>
            <li>Ensure seamless service provision, interactions with users, and third-party engagements.</li>
          </ul>
        </li>
      </BulletList>

      <H2>Public Profile</H2>

      <Copy styleLevel={2} marginBottom={16}>
        When you register or update your profile, the information you provide becomes an integral part of our service.
        Most of this information is displayed on your profile and is intended for sharing with other users. This
        includes your photos, posts, and other content posted through our services, all of which are meant for public
        viewing. It's important to note that once this content is displayed on publicly accessible web pages, it can be
        collected and used by others.
      </Copy>

      <Copy styleLevel={2} marginBottom={16}>
        We want to emphasize that we have limited control over who reads your postings or how other users may use the
        information you voluntarily share. Therefore, it's crucial that you exercise caution and avoid posting
        sensitive, confidential, or any other information you do not wish to be shared publicly. While you retain the
        ability to edit and delete your posts within the service, please be aware that you may not be able to edit or
        delete such information once it has been cached, collected, and stored elsewhere by others.
      </Copy>

      <H2>Retention, Modification, or Deletion of Your Information</H2>

      <Copy styleLevel={2} marginBottom={16}>
        You have the right to review, update, or delete your personal information. You can also opt out of receiving
        promotional communications from us by disabling all marketings in your account settings. Please contact us at
        [contact@JobsBoard.io] to exercise these rights or for any privacy-related inquiries.
      </Copy>

      <Copy styleLevel={2} marginBottom={16}>
        You have the option to modify your Personal Information by editing your profile within your account settings on
        Jobsboard.io. Typically, Jobsboard.io retains your Personal Information as long as your account is active or as
        required to provide you with our services. Certain aspects of your User Personal Information may be retained
        indefinitely, unless you request its deletion. For instance, inactive User accounts are not automatically
        deleted. If you wish to cancel your account or remove your User Personal Information, you can do so via your
        User account settings on Jobsboard.io. However, please note that we might still retain and utilize your
        information to fulfill our legal obligations, settle disputes, and enforce our agreements even after deletion
        requests.
      </Copy>

      <Copy styleLevel={2} marginBottom={16}>
        Following account deletion, specific data, such as messages and comments, may persist in our records on
        Jobsboard.io. Nevertheless, we will either delete or de-identify your Personal Information, including your
        username and email address, by associating them with an anonymous User on Jobsboard.io. Any information we
        delete will be removed from the active database, although it may still exist in our archives on Jobsboard.io.
      </Copy>

      <H2>Disclosure of Your Information</H2>

      <Copy styleLevel={2} marginBottom={16}>
        We may share your personal information with third parties, including service providers and business partners, as
        necessary to fulfill the purposes described in this Privacy Policy. We do not sell your personal information to
        third parties.
      </Copy>

      <H2>Security</H2>

      <Copy styleLevel={2} marginBottom={16}>
        Jobsboard.io is dedicated to ensuring the security of your Personal Information. We employ various
        industry-standard security technologies and procedures to safeguard your Personal Information against
        unauthorized access, use, or disclosure. You are solely responsible for all actions or disclosures related to
        your information and any access to your account.
      </Copy>

      <Copy styleLevel={2} marginBottom={16}>
        It's crucial to acknowledge that no method of transmission over the Internet or electronic storage can be
        guaranteed as completely secure. While Jobsboard.io makes reasonable efforts to protect your Personal
        Information, we cannot assure its absolute security. By using our services, you understand and accept these
        inherent security risks associated with online data transmission. Jobsboard.io disclaims any liability for
        unauthorized access, disclosure, or loss of your Personal Information, and you acknowledge and assume these
        risks when using our platform.
      </Copy>

      <H2>Children's Privacy</H2>

      <Copy styleLevel={2} marginBottom={16}>
        Jobsboard.io is intended for adult users only and is not designed to collect Personal Information from
        individuals under the age of eighteen (18). We do not knowingly gather any Personal Information from users below
        the age of eighteen (18). If you are under eighteen (18) years old, please refrain from providing any Personal
        Information on our platform. If you suspect that we have unintentionally collected Personal Information from an
        individual under eighteen (18), please promptly contact us at support@jobsboard.io so that we can take immediate
        action to address the situation. Your assistance is crucial in maintaining the adult-only nature of our
        services.
      </Copy>

      <H2>Changes to this Privacy Policy</H2>

      <Copy styleLevel={2} marginBottom={16}>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with a revised
        "Effective Date." We encourage you to review this Privacy Policy periodically to stay informed about how we
        collect, use, and protect your information.
      </Copy>

      <H2>Contact Us</H2>

      <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>

      <p>
        <br />
        <strong>JobsBoard.io</strong>
        <br />
        Email: <a href="mailto:contact@JobsBoard.io">contact@JobsBoard.io</a>
        <br />
      </p>

      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/b26031ba-1bea-4fed-b40b-58dbf2cb8d0d/cd.js"
        type="text/javascript"
        async
      ></script>
    </>
  );
};

export default PrivacyPolicy;

const FirstLi = styled.div`
  width: 220px;
`;
